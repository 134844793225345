<!--
 * @Author: weijianjun
 * @Date: 2021-08-13 09:22:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-10 10:25:56
 * @Description: 产线详情
-->
<template>
  <a-spin :spinning="aloading">
    <NeoPageLayout :showFooter="permission">
      <div class="productionLineDetails">
        <header class="pl-header row">
          <div class="pl-header_name">{{ clientName }}</div>
          <div class="pl-header_produce-docking">
            <label class="pl-header_label">{{ $t('companyInfo.Pline_dock') }}：</label>
            <span class="pl-header_value">
              <a-radio-group v-model="produceSystem">
                <a-radio v-show="produceSystem == 2" value="2" class="c-2">{{
                  $t('customerConfig.dock_jingyi_internal')
                }}</a-radio>
                <a-radio v-show="produceSystem == 0" value="0" class="c-2">{{
                  $t('customerConfig.no_dock_produce_sys')
                }}</a-radio>
              </a-radio-group>
            </span>
          </div>
          <div class="pl-header_industry-field">
            <label class="pl-header_label">{{ $t('customerConfig.industry_and_field') }}：</label>
            <span class="pl-header_value">{{ PLInfo.industryField }}</span>
          </div>
          <div class="pl-header_more-info">
            <label class="pl-header_label">{{ $t('customerConfig.moreinfo') }}：</label>
            <span class="pl-header_value">{{ PLInfo.moreInfo }}</span>
          </div>
        </header>
        <div class="Neotrans row">
          <div v-if="produceSystem != 0">
            <div class="title mb-4 mt-4">
              {{ $t('PlineDetails.Neo_setup') }}
            </div>
            <NeoTitle :text="$t('PlineDetails.title_basic_config')" class="tips" />
            <div class="basic_config">
              <a-form-model :colon="false" ref="neoForm" :model="newNeoForm">
                <a-form-model-item
                  :label="$t('customerConfig.program')"
                  :label-col="{ span: 2 }"
                  :wrapper-col="{ span: 6 }"
                  prop="program"
                  :rules="[validate.required]"
                  class="program"
                >
                  <a-select v-model="productLineProgramIds" mode="multiple" :disabled="!edit" @change="onSelectProgram">
                    <a-select-option v-for="(item, index) in programList" :key="index" :value="item.id">{{
                      item.name
                    }}</a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item
                  :label="$t('customerConfig.new_program_client_project')"
                  :label-col="{ span: 3 }"
                  :wrapper-col="{ span: 6 }"
                  prop="clientProject"
                  :rules="[validate.required]"
                  class="client-project"
                >
                  <div style="display: flex">
                    <a-select :disabled="!edit" v-model="newNeoForm.clientProject">
                      <a-select-option v-for="(item, index) in namePidList" :key="index" :value="item.id">{{
                        item.name
                      }}</a-select-option>
                    </a-select>
                    <div class="question_mark_mod">
                      <a-tooltip placement="topLeft" :title="$t('newPline.tooltop')">
                        <img class="question_mark_mod_img" :src="require('@/assets/image/question_mark.png')" alt="" />
                      </a-tooltip>
                    </div>
                  </div>
                </a-form-model-item>
              </a-form-model>
            </div>
            <a-table
              class="head-bg"
              :rowClassName="fontIsRed"
              :pagination="pagination"
              size="small"
              :data-source="tableData"
              :loading="loading"
              rowKey="id"
            >
              <a-table-column width="450px" title="Program">
                <template slot-scope="text">{{ text.name }}</template>
              </a-table-column>
              <a-table-column width="300px" title="Source">
                <template slot-scope="text">{{ text.sourceCode }}</template>
              </a-table-column>
              <a-table-column title="Content Type">
                <template slot-scope="text">{{ text.componentName }}</template>
              </a-table-column>
            </a-table>
            <div class="attention">
              <p>{{ $t('customerConfig.attention_0') }}</p>
              <p>{{ $t('customerConfig.attention_1') }}</p>
              <p>{{ $t('customerConfig.attention_2') }}</p>
              <p>{{ $t('customerConfig.attention_3') }}</p>
            </div>
          </div>
          <NeoTitle :text="$t('PlineDetails.title_person_config')" class="tips mt-2" />
          <div class="person_config">
            <a-form-model :colon="false" ref="form" :model="newNeoForm" labelAlign="left">
              <a-row>
                <a-col :span="12">
                  <a-form-model-item
                    :label="$t('PlineDetails.delivery_manager')"
                    :label-col="{ span: 2 }"
                    :wrapper-col="{ span: 9 }"
                    prop="supplierPm"
                  >
                    <a-select
                      show-search
                      v-model="newNeoForm.supplierPm"
                      :filter-option="filterOption"
                      :disabled="!edit"
                    >
                      <a-select-option v-for="(item, index) in pmList" :key="index" :value="item.userId">{{
                        `${item.name}`
                      }}</a-select-option></a-select
                    >
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <!-- 抄送群组 -->
                  <a-form-model-item
                    :label="$t('PlineDetails.ccEmails')"
                    :label-col="{ span: 2 }"
                    :wrapper-col="{ span: 9 }"
                  >
                    <a-input v-model="ccEmails" :disabled="!edit" />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <a-space>
          <template v-if="edit">
            <a-button @click="handleCancel">{{ $t('cancel') }}</a-button>
            <a-button @click="handleSubmit" :loading="saveLoading" type="primary" class="btn">{{
              $t('save')
            }}</a-button>
          </template>
          <a-button v-else @click="edit = true" type="primary" class="btn">{{ $t('change') }}</a-button>
        </a-space>
      </template>
    </NeoPageLayout>
  </a-spin>
</template>
<script>
const { validate, localeDict } = window.$g
export default {
  name: 'productLineDetail',
  data() {
    return {
      aloading: true,
      id: undefined,
      agencyId: undefined,
      clientName: undefined,
      produceSystem: 0,
      localeDict,
      // 报价配置
      validate,
      productLineProgramIds: [],
      edit: false,
      produceManagerList: [],
      pmList: [],
      newNeoForm: {
        program: undefined,
        clientProject: undefined,
        supplierPm: undefined,
      },
      ccEmails: '',
      newNeoFormCache: {},
      filed: '',
      saveLoading: false,
      loading: false,
      industryField: '',
      info: {},
      pagination: {
        current: 1,
        size: 'middle',
        total: 0,
        pageSize: 5,
        style: {
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        },
        // eslint-disable-next-line no-unused-vars
        onChange: (page) => {
          this.pagination.current = page
        },
      },
      tableData: [],
      programList: [],
      namePidList: [],
      PLInfo: {
        industryField: '',
        moreInfo: '',
      },
    }
  },
  computed: {
    q() {
      return this.$route.query
    },
    permission() {
      return this.$route.query.permission === 'true'
    },
    comMapping() {
      return this.$route.query.comMapping
    },
  },
  watch: {
    edit(edit) {
      if (edit) {
        if (this.info.ccEmails == null) {
          this.getccEmailsTemp()
        }
      } else {
        console.log('this.info :>> ', this.info)
        this.ccEmails = this.info.ccEmails
      }
    },
  },
  created() {
    const { id, clientName, produceSystem } = this.q
    this.id = id
    this.produceSystem = produceSystem
    this.clientName = clientName
    setTimeout(() => {
      this.aloading = false
    }, 1500)
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getProductionLineDetails_2()
      this.getProgramList()
      this.getPmList()
      this.getNamePidList()
    },
    async getccEmailsTemp() {
      try {
        const params = {}
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('ccEmailsTemp', params)
        console.log(`ccEmailsTemp-data`, data)
        console.log('data.ccEmails :>> ', data.ccEmails)
        this.ccEmails = data.ccEmails
        console.log('this.newNeoForm :>> ', this.newNeoForm)
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    fontIsRed(record) {
      const table = this.tableData
      const { id: myid, sourceCode, componentId } = record
      const isred = table.some((item) => {
        if (myid !== item.id) {
          return item.sourceCode === sourceCode && item.componentId === componentId
        } else {
          return false
        }
      })
      if (isred) {
        return 'redfont'
      } else {
        return ''
      }
    },
    async onSaveNewNeo() {
      const table = this.tableData
      const arr = []
      console.log('this.newNeoForm :>> ', this.newNeoForm)
      const ccEmails = this.ccEmails
      console.log('ccEmails :>> ', ccEmails)
      console.log(`table`, table)
      if (table.length >= 2) {
        table.forEach((item1) => {
          const isSame = table.some((item2) => {
            if (item1.id !== item2.id) {
              return item2.sourceCode === item1.sourceCode && item2.componentId === item1.componentId
            } else {
              return false
            }
          })
          if (isSame) {
            arr.push(item1)
          }
        })
        console.log(`arr`, arr)
        if (arr.length > 0) {
          let tooltop = ''
          let names = ''
          arr.forEach((item, idx) => {
            if (idx === 0) {
              names = `"${item.name}"`
            } else {
              names = names + '、' + `"${item.name}"`
            }
          })
          tooltop = names
          console.log(`tooltop：`, tooltop)
          return this.$message.error(this.$t('newPline.source_content', { tooltop }), 5)
        }
      }
      this.$refs.neoForm.validate(async (valid) => {
        if (valid) {
          this.saveLoading = true
          try {
            const { program = [] } = this.newNeoForm
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('productLineConfigSave', {
              ccEmails,
              pid: this.newNeoForm.clientProject,
              projectManager: this.newNeoForm.supplierPm,
              productLineId: this.id,
              updateProgramLineRequestList: program.map((id) => {
                const programItem = this.programList.find((pInfo) => pInfo.id === id) || {}
                console.log('programItem' + programItem)
                return {
                  pid: programItem.clientProjectId,
                  programId: programItem.id,
                  programName: programItem.name,
                  contentType: programItem.componentId,
                  contentTypeName: programItem.componentName,
                  source: programItem.sourceCode,
                }
              }),
            })
            this.saveLoading = false
            this.edit = false
            this.getProductionLineDetails_2()

            // this.$emit('update')
          } catch (err) {
            this.saveLoading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    // 供应商产品线表-产品线生产配置信息
    async getProductionLineDetails_2() {
      this.loading = true
      try {
        const data = await this.$http({
          key: 'fetchProductLineInfo',
          params: { id: this.id },
        })
        const { industry, remark, pid, productLineProgramIds, projectManager } = data
        this.newNeoForm = {
          program: productLineProgramIds,
          clientProject: pid,
          supplierPm: projectManager,
        }
        this.newNeoFormCache = { ...this.newNeoForm }
        this.PLInfo = {
          industryField: industry,
          moreInfo: remark,
        }
        this.info = data
        this.ccEmails = this.info.ccEmails
        this.productLineProgramIds = productLineProgramIds != null ? productLineProgramIds : []
        // 根据productLineProgramIds查找对应的表格数据
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
    // 供应商产品线表-获取program列表
    async getProgramList() {
      try {
        const data = await this.$http('fetchProgramList', {
          productLineConfigId: this.id,
        })
        this.programList = data
        this.productLineProgramIds.forEach((val) => {
          let result = this.programList.filter((item) => {
            if (item.id === val) {
              return item
            }
          })
          this.tableData.push(...result)
          console.log(this.tableData)
        })
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 对接鲸译-获取clientproject
    async getNamePidList() {
      try {
        const data = await this.$http('namePidList', {
          name: this.comMapping,
        })
        this.namePidList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 供应商产品线表-交付经理列表
    async getPmList() {
      try {
        const data = await this.$http('getUserDetailList')
        this.pmList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 搜索下拉
    filterOption(input, option) {
      const data = option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      return data
    },
    onSelectProgram(value) {
      console.log(`value`, value)
      this.newNeoForm.program = this.productLineProgramIds

      this.tableData = []
      this.updateProgramLineRequestList = []
      value.forEach((val) => {
        // let that = this
        let result = this.programList.filter((item) => {
          if (item.id === val) {
            this.updateProgramLineRequestList.push({
              pid: undefined,
              programId: item.id,
              programName: item.name,
              contentType: item.componentId,
              contentTypeName: item.componentName,
              source: item.sourceCode,
            })
            return item
          }
        })
        this.tableData.push(...result)
      })
    },
    handleCancel() {
      this.newNeoForm = { ...this.newNeoFormCache }
      // 对接生产系统，需要重置生产配置
      if (this.produceSystem == 2) {
        this.$refs.neoForm.resetFields()
        this.productLineProgramIds = this.newNeoFormCache.program != null ? this.newNeoFormCache.program : []
        this.tableData = []
        this.updateProgramLineRequestList = []
        this.productLineProgramIds.forEach((val) => {
          // let that = this
          let result = this.programList.filter((item) => {
            if (item.id === val) {
              this.updateProgramLineRequestList.push({
                pid: undefined,
                programId: item.id,
                programName: item.name,
                contentType: item.componentId,
                contentTypeName: item.componentName,
                source: item.sourceCode,
              })
              return item
            }
          })
          this.tableData.push(...result)
        })
      }
      this.edit = false
    },
    // 不对接生产系统产线保存
    async handleAssignPM() {
      this.saveLoading = true
      try {
        const ccEmails = this.ccEmails
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('productLineConfigSave', {
          ccEmails,
          projectManager: this.newNeoForm.supplierPm,
          productLineId: this.id,
        })
        this.saveLoading = false
        this.edit = false
        this.getProductionLineDetails_2()
        // this.$emit('update')
      } catch (err) {
        this.saveLoading = false
        this.$httpNotify(err)
      }
    },
    handleSubmit() {
      if (this.produceSystem == 2) {
        this.onSaveNewNeo()
      } else if (this.produceSystem == 0) {
        // 不对接生产系统
        this.handleAssignPM()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.productionLineDetails {
  .row {
    background: #ffffff;
    box-shadow: 0px 2px 15px #f0f6fd;
    opacity: 1;
    border-radius: 4px;
  }
  .pl-header {
    padding-left: 20px;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    > div:not(:last-of-type) {
      margin-bottom: 20px;
    }
    &_name {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 29px;
      color: #46546c;
    }
    &_label {
      width: 77px;
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 13px;
      color: #46546c;
      display: inline-block;
      text-align: right;
    }
    &_value {
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 13px;
      color: #8895ab;
      margin-left: 10px;
      display: inline-block;
    }
  }
  .question_mark_mod {
    width: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 6px;
    &_img {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
  ::v-deep {
    .ant-form-item-label {
      text-align: left;
    }
    .program,
    .person_config {
      .ant-form-item-label {
        width: auto;
      }
      .ant-form-item-control-wrapper {
        width: 675px;
      }
    }
    .client-project {
      .ant-form-item-label {
        width: auto;
      }
      .ant-form-item-control-wrapper {
        width: 530px;
      }
    }
  }
  .person_config-delivery_manager {
    width: auto !important;
    padding-right: 10px;
  }
  .title {
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 13px;
    color: #46546c;
    margin-top: 8px;
    margin-bottom: 19px;
  }
  .tips {
    margin-bottom: 15px;
  }
  .text {
    font-weight: 400;
    font-size: @font_size_1;
  }
  .text_title {
    color: @text_color_1;
  }
  .text_content {
    color: @text_color_2;
  }
  /deep/.ant-col-3 {
    text-align: right;
    display: block;
    box-sizing: border-box;
    width: 10.5%;
    margin-bottom: 10px;
  }
  .card_bg {
    background: @background-color_3;
    background: @background-color_2;
    border: 1px solid rgba(212, 218, 229, 0.5019607843137255);
    box-shadow: 0px 6px 16px rgba(189, 197, 211, 0.2);
    border-radius: @border-radius_1;
  }
  .base {
    padding: 22px 20px 20px;
    /deep/.ant-descriptions-row {
      text-align: center;
      font-size: @font_size_1;
    }
    /deep/.ant-descriptions-item-label {
      font-weight: bold;
      color: @text_color_2;
    }
  }

  .Neotrans {
    padding: 22px 20px 10px;
  }
  .person_config {
    .select-input {
      width: 250px;
      transform: translateY(-10px);
    }
  }
  .btn {
    min-width: 100px !important;
  }
  .attention {
    margin-top: 50px;
    p {
      margin: 0;
      color: #f56c6c;
    }
  }
  .basic_config {
    /deep/.ant-form-item {
      margin-bottom: 10px;
    }
  }
  //设置padding
  ::v-deep .ant-table-body {
    tr > th:first-child {
      padding-left: 45px !important;
    }
    // tr > th:last-child {
    //   padding-right: 45px !important;
    // }
    .ant-table-tbody {
      & > tr > td:first-child {
        padding-left: 45px !important;
      }
      & > tr > td:last-child {
        padding-right: 45px !important;
      }
      .redfont {
        color: #f56c6c;
      }
    }
  }
}
</style>
